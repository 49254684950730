import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from "../sidebars/AboutSidebar";
import EnquiryNow from "../common/EnquiryNow3";

const CocHostel = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="mvvinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Code of Conduct <br></br>(Boardes of Hostel)</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>
            Code of Conduct (Boardes of Hostel)
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">
              CODE OF CONDUCT FOR THE BOARDES OF HOSTEL
            </h2>
            <p>
              <h className="font-weight-bold fs-5">Introduction :</h>
              The boards of the hostels are expected to uphold high standard of
              ethical and moral standard following code of conduct in outlined
              for its.
            </p>
            <h className="font-weight-bold fs-5 ">General Conduct</h>

            <ul>
              <h className="font-weight-bold fs-5">Respect for Others</h>
              <li>
                Treat all residents, staff and visitors with respect, kindness
                and courtesy.
              </li>
              <li>
                Avoid engaging in behaviour that may cause harm, discomfort, or
                distress to others.
              </li>
              <li>
                Respect the privacy and personal space of fellow residents.
              </li>
            </ul>
            <ul>
              <h className="font-weight-bold fs-5">Quiet Hours</h>
              <li>
                Quiet hours are enforced from 9:30 PM to 7:00 AM every day
              </li>
              <li>
                Keep noise levels to a minimum during quiet hours to ensure a
                healthy environment for studying and rest.
              </li>
            </ul>
            <ul>
              <h className="font-weight-bold fs-5">Cleanliness</h>
              <li>Keep your living space clean and tidy at all times.</li>
              <li>
                Dispose of garbage properly and follow recycling guidelines.
              </li>
              <li>
                Report any maintenance issues promptly to hostel admin or
                warden.
              </li>
            </ul>
            <ul>
              <h className="font-weight-bold fs-5">Safety and Security</h>
              <li>
                Do not compromise the safety and security of yourself or others.
              </li>
              <li>
                Always lock your room when leaving and do not prop doors open.
              </li>
              <li>
                Do not allow unauthorized individuals to enter the hostel
                premises.
              </li>
            </ul>
            <ul>
              <h className="font-weight-bold fs-5">
                Use of Alcohol and Toxic Substance
              </h>
              <li>
                Consumption of alcohol and use of any illegal toxic substances
                are strictly prohibited in the hostel.
              </li>
              <li>
                Do not bring or store alcohol or any illegal toxic substances in
                the hostel premises.
              </li>
            </ul>
            <ul>
              <h className="font-weight-bold fs-5">Smoking</h>
              <li>
                Smoking is strictly prohibited within the hostel building,
                including individual rooms.
              </li>
            </ul>
            <ul>
              <h className="font-weight-bold fs-5">
                Compliance with University Policies
              </h>
              <li>
                Adhering to all university policies and regulations including
                those related to conduct, academic integrity less proper use of
                facilities.
              </li>
            </ul>
            <ul>
              <h className="font-weight-bold fs-5">Disciplinary Action</h>
              <li>
                Violation of the hostel code of conduct will attract in
                disciplinary action, including warnings, fines and suspension
                from hostel privileges or expulsion from the hostel. Serious or
                repeated violations may also result in disciplinary action by
                the university.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default CocHostel;
