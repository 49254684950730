import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import logo from "../../Images/logo/isbm-university.webp";
import laptopgirl from "../../Images/commonimg/english_teacher_doing_her_lessons_online_1_634dad1575.png";

function EnquiryNow3({ show, onHide }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [levels, setLevels] = useState([]);
  const [courses, setCourses] = useState([]);
  const formRef = useRef(null);  // Define the formRef here

  const [formData, setFormData] = useState({
    state: "",
    city: "",
    level: "",
    course: "",
  });

  // Other useEffect hooks remain unchanged

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const form = new FormData(formRef.current);  // Use formRef here

    try {
      const response = await fetch(
        "https://isbmuniversity.edu.in/submitLead.php",
        {
          method: "POST",
          body: form,
        }
      );
      if (response.ok) {
        console.log("Form submitted successfully");
      } else {
        console.error("Form submission failed");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      className="enquiry_formss"
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="custom-modal modal-xl"
    >
      <Modal.Header closeButton style={{ display: "end" }} />
      <Modal.Body className="abcd_modal_abcd mb-4" style={{ borderRadius: "2rem" }}>
        <Row>
          <Col lg={5}>
            <img className="img_enqurymodal" src={laptopgirl} alt="Laptop Girl" />
          </Col>
          <Col lg={7}>
            <form ref={formRef} onSubmit={handleSubmit} className="enquiryform">
              <h2 className="enquirymodalheading">
                Connect With Our Counsellor
              </h2>
              <p className="enquirymodalheading2">
                "Please provide the information below, and our counselor will
                get in touch with you."
              </p>
              <Row>
                <div className="form-group col-lg-6">
                  <label className="mt-3 text-blue">Enter Your Name</label>
                  <input
                    type="text"
                    className="form-control form-control2 mb-3"
                    id="name"
                    name="name"
                    placeholder="Enter your Name"
                    required
                  />
                </div>
                <div className="form-group col-lg-6">
                  <label className="mt-3 text-blue">Enter Your Email</label>
                  <input
                    type="email"
                    className="form-control form-control2 mb-3"
                    id="email"
                    name="email"
                    placeholder="Enter your Email"
                    required
                  />
                </div>
              </Row>
              <Row>
                <div className="form-group col-lg-6 mb-3">
                  <label className="mt-2 text-blue">Enter Your Number</label>
                  <input
                    type="number"
                    maxLength="10"
                    style={{ width: "100%" }}
                    id="mobile_code"
                    className="form-control form-control2"
                    placeholder="Phone Number"
                    name="phone"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="form-group col-lg-6 mb-3">
                  <label className="mt-2 text-blue">Select Your State</label>
                  <select
                    className="form-control form-control2"
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={(e) =>
                      setFormData({ ...formData, state: e.target.value })
                    }
                    required
                  >
                    <option value="">Select State</option>
                    {states.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
              </Row>
              <Row>
                <div className="form-group col-lg-6 mb-3">
                  <label className="mt-2 text-blue">Select Your City</label>
                  <select
                    className="form-control form-control2"
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={(e) =>
                      setFormData({ ...formData, city: e.target.value })
                    }
                    required
                  >
                    <option value="">Select City</option>
                    {cities.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-lg-6 mb-3">
                  <label className="mt-2 text-blue">
                    Degree you are looking for *
                  </label>
                  <select
                    className="form-control form-control2"
                    id="level"
                    name="level"
                    value={formData.level}
                    onChange={(e) =>
                      setFormData({ ...formData, level: e.target.value })
                    }
                    required
                  >
                    <option value="">Select level</option>
                    {levels.map((level, index) => (
                      <option key={index} value={level}>
                        {level}
                      </option>
                    ))}
                  </select>
                </div>
              </Row>
              <div className="form-group mb-3">
                <label className="mt-2 text-blue">
                  Programs you are looking for *
                </label>
                <select
                  className="form-control form-control2"
                  id="course"
                  name="course"
                  value={formData.course}
                  onChange={(e) =>
                    setFormData({ ...formData, course: e.target.value })
                  }
                  required
                >
                  <option value="">Select course</option>
                  {courses.map((course, index) => (
                    <option key={index} value={course}>
                      {course}
                    </option>
                  ))}
                </select>
              </div>
              <input
                type="text"
                className="form-control form-control2"
                id="current_url"
                name="current_url"
                value={window.location.href}
                hidden
              />
              <input
                type="text"
                name="utm_source"
                value={
                  new URLSearchParams(window.location.search).get(
                    "utm_source"
                  ) || ""
                }
                hidden
              />
              <input
                type="text"
                name="utm_medium"
                value={
                  new URLSearchParams(window.location.search).get(
                    "utm_medium"
                  ) || ""
                }
                hidden
              />
              <input
                type="text"
                name="utm_campaign"
                value={
                  new URLSearchParams(window.location.search).get(
                    "utm_campaign"
                  ) || ""
                }
                hidden
              />
              <div className="form-check">
                <input
                  className="form-check-input mt-2"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                  required
                />
                <label
                  className="form-check-label text-blue"
                  htmlFor="flexCheckChecked"
                >
                  By clicking on the above button, I authorise ISBMU and/or
                  their representative to contact me via Phone, SMS, WhatsApp,
                  and/or email.
                </label>
              </div>
              <div className="form-group d-flex justify-content-center">
                <input
                  type="submit"
                  name="submit"
                  value="Submit"
                  className="btn btn-primary mt-3 enquirybutton"
                  disabled={isSubmitting}
                />
              </div>
            </form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default EnquiryNow3;
