import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import logo from "../../Images/logo/isbm-university.webp";
import { Modal } from "react-bootstrap";
import $ from "jquery";
import laptopgirl from "../../Images/commonimg/english_teacher_doing_her_lessons_online_1_634dad1575.webp";

function EnquiryNow4({ show, onHide }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef(null);

  // Using useLayoutEffect to ensure scripts run before modal is displayed
  useLayoutEffect(() => {
    if (show) {
      const fetchCity = () => {
        var state = $("#state").val();

        $.ajax({
          type: "POST",
          url: "https://insityapp.com/dbFiles/fetch_cities.php",
          data: { state },
          dataType: "json",
          success: function (response) {
            var city = $(".city");
            city.empty();
            response.cities.forEach((name) => {
              city.append($("<option>", { value: name, text: name }));
            });
          },
          error: function (error) {
            console.error("Error fetching cities:", error);
          },
        });
      };

      const fetchCourse = () => {
        var level = $("#level").val();
        var entity = "ISBMU";

        $.ajax({
          type: "POST",
          url: "https://insityapp.com/dbFiles/fetch_courses.php",
          data: { level, entity },
          dataType: "json",
          success: function (response) {
            var course = $(".course");
            course.empty();
            response.courses.forEach((name) => {
              course.append($("<option>", { value: name, text: name }));
            });
          },
          error: function (error) {
            console.error("Error fetching courses:", error);
          },
        });
      };

      // Fetch states and levels when the component mounts
      $.ajax({
        type: "POST",
        url: "https://insityapp.com/dbFiles/fetch_states.php",
        dataType: "json",
        success: function (response) {
          var state = $(".state");
          state.empty();
          state.append($("<option>", { value: "", text: "Select State" }));
          response.states.forEach((name) => {
            state.append($("<option>", { value: name, text: name }));
          });
        },
        error: function (error) {
          console.error("Error fetching states:", error);
        },
      });

      var entity = "ISBMU";
      $.ajax({
        type: "POST",
        url: "https://insityapp.com/dbFiles/fetch_levels.php",
        data: { entity },
        dataType: "json",
        success: function (response) {
          var level = $(".level");
          level.empty();
          level.append($("<option>", { value: "", text: "Select Level" }));
          response.levels.forEach((name) => {
            level.append($("<option>", { value: name, text: name }));
          });
        },
        error: function (error) {
          console.error("Error fetching levels:", error);
        },
      });

      $(".state").on("change", fetchCity);
      $(".level").on("change", fetchCourse);

      // Cleanup function to remove event listeners when component unmounts
      return () => {
        $(".state").off("change", fetchCity);
        $(".level").off("change", fetchCourse);
      };
    }
  }, [show]); // Ensure this runs when `show` changes

  const handleSubmit = (e) => {
    setIsSubmitting(true);
    // Gather form data
    const formData = new FormData(e.target);

    // Send form data using fetch
    fetch("https://isbmuniversity.edu.in/submitLead.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          console.log("Form submitted successfully");
          // Handle successful submission
        } else {
          console.error("Form submission failed");
          // Handle submission failure
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setTimeout(() => {
          setIsSubmitting(false); // Reset button after submission is complete
        }, 2000); // Example delay - replace with actual form submission logic
      });
  };

  return (
    <Modal
      className="enquiry_formss"
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
        dialogClassName="custom-modal modal-xl"
    >
       <Modal.Header closeButton style={{ display: "end" }}>
       
      </Modal.Header>
      <Modal.Body className="abcd_modal_abcd mb-4"  style={{ borderRadius: "2rem" }}  >
       <Row>
       <Col lg={5}>
         <img className="img_enqurymodal" src={laptopgirl}/>
       </Col>
       <Col lg={7}>
        <form
          ref={formRef}
          action="https://isbmuniversity.edu.in/submitLead.php"
          method="post"
          onSubmit={handleSubmit}
          class="enquiryform"
        >
        <h2 class="enquirymodalheading">Connect With Our Counsellor</h2>
        <p class="enquirymodalheading2">"Please provide the information below, and our counselor will get in touch with you."</p>
         <Row>
          <div className="form-group col-lg-6">
            <label  className="mt-3 text-blue">Enter Your Name</label>
            <input
              type="text"
              className="form-control form-control2  mb-3"
              id="name"
              name="name"
              placeholder="Enter your Name"
              required
            />
          </div>
          <div className="form-group col-lg-6">
          <label  className="mt-3 text-blue">Enter Your Email</label>
            <input
              type="Email"
              className="form-control form-control2 mb-3"
              id="email"
              name="email"
              placeholder="Enter your Email"
              required
            />
           
          </div>
        </Row>
        <Row>
          <div className="form-group col-lg-6 mb-3" >
          <label  className="mt-2 text-blue">Enter Your Number</label>
            <input
              type="number"
              maxLength="10"
              style={{ width: "100%" }}
              id="mobile_code"
              className="form-control form-control2"
              placeholder="Phone Number"
              name="phone"
              autoComplete="off"
              required
            />
          </div>
          <div className="form-group col-lg-6 mb-3">
          <label  className="mt-2 text-blue">Select Your State </label>
            <select
              className="form-control form-control2 state"
              id="state"
              name="state"
              required
            >
              <option value="">Select State</option>
            </select>
          </div>
          </Row>
          <Row>
          <div className="form-group col-lg-6 mb-3">
          <label  className="mt-2 text-blue">Select Your City</label>
            <select
              className="form-control form-control2 city"
              id="city"
              name="city"
              required
            >
              <option value="">Select City</option>
            </select>
          </div>
        
         
          <div className="form-group col-lg-6 mb-3">
          <label  className="mt-2 text-blue">Degree you are looking for *</label>
            <select
              className="form-control form-control2 level"
              id="level"
              name="level"
              required
            >
              <option value="">Select level</option>
            </select>
          </div>
          </Row>
          <div className="form-group mb-3">
          <label  className="mt-2 text-blue">Programs you are looking for *</label>
            <select
              className="form-control form-control2 course"
              id="course"
              name="course"
              required
            >
              <option value="">Select course</option>
            </select>
          </div>
          <input
            type="text"
            className="form-control form-control2"
            id="current_url"
            name="current_url"
            value={`${window.location.href}`}
            hidden
          />
          <input
            type="text"
            name="utm_source"
            value={
              new URLSearchParams(window.location.search).get("utm_source") ||
              ""
            }
            hidden
          />
          <input
            type="text"
            name="utm_medium"
            value={
              new URLSearchParams(window.location.search).get("utm_medium") ||
              ""
            }
            hidden
          />
          
          <input
            type="text"
            name="utm_campaign"
            value={
              new URLSearchParams(window.location.search).get("utm_campaign") ||
              ""
            }
            hidden
          />
          <div class="form-check">
          <input class="form-check-input mt-2" type="checkbox" value="" id="flexCheckChecked"/>
           <label class="form-check-label text-blue" for="flexCheckChecked">
           By clicking on above button, I authorise ISBMU and/or their representative to contact me via. Phone, SMS, WhatsApp and/or email.
          </label>
          </div>
          <div className="form-group d-flex justify-content-center">
            <input
              type="submit"
              name="submit"
              value="Submit"
              className="btn btn-primary mt-3 enquirybutton"
              disabled={isSubmitting}
            />
          </div>
        </form>
        </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default EnquiryNow4;


