import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Accordion } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Servicesslidebaar from "../sidebars/ServicesSidebar";
import EnquiryNow from "../common/EnquiryNow3";

const AntiRaggingCommittees = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="antiragginginnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Anti-Ragging Committees</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Anti-Ragging Committees</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Servicesslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Anti-Ragging Committees</h2>
            <div className="position-relative card table-nowrap table-card">
              <div className="table-responsive committe-table">
                <table className="table mb-0">
                  <thead className="small text-uppercase bg-body text-muted bg-secondary">
                    <tr>
                      <th>Name of Member</th>
                      <th>Designation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="small">Prof. (Dr) AnandMahalwar </td>
                      <td className="small">Chairman </td>
                    </tr>
                    <tr>
                      <td className="small">Dr. B. P. Bhol </td>
                      <td className="small">Registrar</td>
                    </tr>
                    <tr>
                      <td className="small">Dr. Sandeep Kumar </td>
                      <td className="small">DSW, Member,</td>
                    </tr>
                    <tr>
                      <td className="small">Dr. Shameen Ahmad Khan </td>
                      <td className="small">ARC, Member,</td>
                    </tr>
                    <tr>
                      <td className="small">Dr. Arun Kumar Singh </td>
                      <td className="small">ARC, Member,</td>
                    </tr>
                    <tr>
                      <td className="small">Dr. N. Kumar Swamy </td>
                      <td className="small">ARC, Member,</td>
                    </tr>
                    <tr>
                      <td className="small">Mr. R.K Deshmukh </td>
                      <td className="small">Hostel Warden (Boys)</td>
                    </tr>
                    <tr>
                      <td className="small">Dr. Tripti Shastri</td>
                      <td className="small">Hostel Warden (Girls)</td>
                    </tr>
                    <tr>
                      <td className="small">TI, Chhura </td>
                      <td className="small">
                        Member representative of Police Admin
                      </td>
                    </tr>
                    <tr>
                      <td className="small">Mr. YashwantYadaw (Media) </td>
                      <td className="small">Member Local Media </td>
                    </tr>
                    <tr>
                      <td className="small">Mr. SheetalDhruw (NGO) </td>
                      <td className="small">Member NGO </td>
                    </tr>
                    <tr>
                      <td className="small">Mr. PukhrajYadaw </td>
                      <td className="small">Member Non teaching</td>
                    </tr>
                    <tr>
                      <td className="small">Mr. Arjun Singh </td>
                      <td className="small">Member (Administration)</td>
                    </tr>
                    <tr>
                      <td className="small">Mr. Chamru Ram</td>
                      <td className="small">Member Parents </td>
                    </tr>
                    <tr>
                      <td className="small">Mr. Dharmendra Chandrakar</td>
                      <td className="small">Member Parents </td>
                    </tr>
                    <tr>
                      <td className="small">Ms. Rukhmani (BA) </td>
                      <td className="small">Member Student – Fresher’s</td>
                    </tr>
                    <tr>
                      <td className="small">Shishir Chandrakar (B.Com)</td>
                      <td className="small">Member Student – Fresher’s</td>
                    </tr>
                    <tr>
                      <td className="small">Ms. Manisha Dhruw (BA)</td>
                      <td className="small">Member Senior Student</td>
                    </tr>
                    <tr>
                      <td className="small">Mr. Cheman Lal (B.Com)</td>
                      <td className="small">Member Senior Student</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default AntiRaggingCommittees;
