import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Accordion } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Servicesslidebaar from "../sidebars/ServicesSidebar";
import FORM1 from "../../Images/FORM/Application form for Provisional & Transcript Certificate.pdf";
import FORM2 from "../../Images/FORM/ATKT_FORM.pdf";
import FORM3 from "../../Images/FORM/BONAFIDE_APPLICATION_FORM.pdf";
import FORM4 from "../../Images/FORM/Change of Name and Surname form.pdf";
import FORM5 from "../../Images/FORM/Degree Form.pdf";
import FORM6 from "../../Images/FORM/DiplomaCertificate.pdf";
import FORM7 from "../../Images/FORM/Duplicate marksheet form.pdf";
import FORM8 from "../../Images/FORM/Fee istallment declaration form (1).pdf";
import FORM9 from "../../Images/FORM/isbm university Admission form.pdf";
import FORM10 from "../../Images/FORM/isbm university new - bus facility form.pdf";
import FORM11 from "../../Images/FORM/isbm university new - Hostel form.pdf";
import FORM12 from "../../Images/FORM/isbm university new - ND for TC-CC-MG.pdf";
import FORM13 from "../../Images/FORM/isbm university new - No Dues Form.pdf";
import FORM15 from "../../Images/FORM/Re Registration form.pdf";

import EnquiryNow from "../common/EnquiryNow3";
 

const Downloads  = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <Topnav />
      <Menubar />
      <div className="antiragginginnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Download</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Download</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Servicesslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Download</h2>
            <ul >
              <li className="downloadform">
                <div className="formIcon">
                  <i className="fa fa-wpforms" aria-hidden="true"></i>
                </div>
                <div className="formname">
                  <a target="/" href={FORM1} >
                    Application form for Provisional & Transcript Certificate
                  </a>
                </div>
              </li>
              <li className="downloadform">
                <div className="formIcon">
                  <i className="fa fa-wpforms" aria-hidden="true"></i>
                </div>
                <div className="formname">
                  <a target="/" href={FORM2} >
                    ATKT/Supplementary Form
                  </a>
                </div>
              </li>
              <li className="downloadform">
                <div className="formIcon">
                  <i className="fa fa-wpforms" aria-hidden="true"></i>
                </div>
                <div className="formname">
                  <a target="/" href={FORM3} >
                    Application for Issue of Bonafide Certificate
                  </a>
                </div>
              </li>
              <li className="downloadform">
                <div className="formIcon">
                  <i className="fa fa-wpforms" aria-hidden="true"></i>
                </div>
                <div className="formname">
                  <a target="/" href={FORM4} >
                    Procedure for Change of Name and Surname
                  </a>
                </div>
              </li>
              <li className="downloadform">
                <div className="formIcon">
                  <i className="fa fa-wpforms" aria-hidden="true"></i>
                </div>
                <div className="formname">
                  <a target="/" href={FORM5} >
                    Application Form for Issue of Degree Certificate
                  </a>
                </div>
              </li>
              <li className="downloadform">
                <div className="formIcon">
                  <i className="fa fa-wpforms" aria-hidden="true"></i>
                </div>
                <div className="formname">
                  <a target="/" href={FORM6} >
                    Application Form for Issue of Diploma/PG Diploma Certificate
                  </a>
                </div>
              </li>
              <li className="downloadform">
                <div className="formIcon">
                  <i className="fa fa-wpforms" aria-hidden="true"></i>
                </div>
                <div className="formname">
                  <a target="/" href={FORM7} >
                    Application Form for Issue of Duplicate Mark sheet
                  </a>
                </div>
              </li>
              <li className="downloadform">
                <div className="formIcon">
                  <i className="fa fa-wpforms" aria-hidden="true"></i>
                </div>
                <div className="formname">
                  <a target="/" href={FORM8} >
                    Fee Installment Declaration Form
                  </a>
                </div>
              </li>
              <li className="downloadform">
                <div className="formIcon">
                  <i className="fa fa-wpforms" aria-hidden="true"></i>
                </div>
                <div className="formname">
                  <a target="/" href={FORM9}>
                    Admission form
                  </a>
                </div>
              </li>
              <li className="downloadform">
                <div className="formIcon">
                  <i className="fa fa-wpforms" aria-hidden="true"></i>
                </div>
                <div className="formname">
                  <a target="/" href={FORM10} >
                    Bus Facility Form
                  </a>
                </div>
              </li>
              <li className="downloadform">
                <div className="formIcon">
                  <i className="fa fa-wpforms" aria-hidden="true"></i>
                </div>
                <div className="formname">
                  <a target="/" href={FORM11}>
                    Hostel Form
                  </a>
                </div>
              </li>
              <li className="downloadform">
                <div className="formIcon">
                  <i className="fa fa-wpforms" aria-hidden="true"></i>
                </div>
                <div className="formname">
                  <a target="/" href={FORM12} >
                    Application for Transfer Certificate/ Migration Certificate/
                    Character Certificate
                  </a>
                </div>
              </li>
              <li className="downloadform">
                <div className="formIcon">
                  <i className="fa fa-wpforms" aria-hidden="true"></i>
                </div>
                <div className="formname">
                  <a target="/" href={FORM13} >
                    No Dues Form
                  </a>
                </div>
              </li>
              <li className="downloadform">
                <div className="formIcon">
                  <i className="fa fa-wpforms" aria-hidden="true"></i>
                </div>
                <div className="formname">
                  <a target="/" href={FORM15} >
                    Readmission Form
                  </a>
                </div>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Downloads;
