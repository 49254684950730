import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from"../sidebars/AboutSidebar";
import EnquiryNow from "../common/EnquiryNow3";


const Cocmanagement = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    
      <Topnav />
      <Menubar />
      <div className="mvvinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Code of Conduct (Management)</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Code of Conduct (Management)</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
          <h2 className="linkabout123">
              CODE OF CONDUCT MANAGEMENT
            </h2>
            <p>
              <h className="font-weight-bold fs-5">
                Introduction :
              </h>
              A management code of conduct in a University portrays the
              anticipated conduct, obligations value and moral criteria the
              management of the university authorities are expected to follow.
              The overview of the code of conduct for management is given as
              under:-
            </p>
            <ol type="1">
              <li>
                Commitment to Mission and Vision: Management must demonstrate a
                strong dedication to maintain the goal, vision, mission and
                values of the university, which typically encompass ideals of
                academic superiority, honesty, social inclusiveness, and public
                service.
              </li>
              <li>
                Ethical Behaviour: Management should exhibit ethical behaviour
                by consistently displaying honesty, integrity, and transparency
                in all its activities and preference. Management should avoid
                any conflicts of interest and prioritize the best interests of
                the university and its stakeholders.
              </li>
              <li>
                Compliance with Laws and Regulations: Management must adhere to
                all relevant rules, regulations, and policies that govern higher
                education, particularly those pertaining to academic standards,
                financial management, and equal opportunity.
              </li>
              <li>
                Respect for Diversity and Inclusion: Management should actively
                foster a diverse, inclusive, and dignified atmosphere that
                appreciates and acknowledges the contributions of all
                individuals within the university community, irrespective of
                their race, ethnicity, gender, religion, sexual orientation,
                disability or any other.
              </li>
              <li>
                Professionalism and Collegiality: Management should uphold
                professionalism by demonstrating appropriate behaviour and
                cultivating collegial relationships with faculty, staff,
                students, and other stakeholders. It includes encouraging
                collaboration, teamwork, and mutual respect.
              </li>
              <li>
                Resource Stewardship: Management should responsibly manage the
                University's resources, such as finances, personnel, and
                physical assets, for ensuring their efficient and effective
                utilization to achieve the University's mission, vision and
                strategic objectives.
              </li>
              <li>
                Accountability and Transparency: Accountability and transparency
                are essential in management. Management authorities should be
                held responsible for their actions and decisions, ensuring that
                they communicate clearly, document their choices and provide
                justification. They should also accept responsibility for any
                mission and commission.
              </li>
              <li>
                Confidentiality and Privacy: It is important for management to
                uphold the confidentiality and privacy of sensitive information,
                such as student records, personnel issues and proprietary data.
                It means that such information should only be disclosed in case
                of necessity.
              </li>
              <li>
                Conflict Management: Management should strive to prevent or
                settle conflicts and disputes in a constructive and fair manner
                by promoting a culture of transparent communication, fairness
                and reconciliation.
              </li>
              <li>
                Continuous Improvement: Management must actively seek chances
                for professional development, learning and enhancement of skill
                and knowledge by employing effective methods in higher education
                administration and adapting to changing circumstances.
              </li>
            </ol>
            <p>
              <h className="font-weight-bold fs-5">
                Note :
              </h>
              Universities must perform regular reviews and updates of their
              code of conduct for management to ensure that it remains relevant
              and effective in fostering ethical behaviour and maintaining
              institutional integrity. In addition, training and support
              programmes may be offered to assist management professionals in
              comprehending and adhering to the concepts portrayed in the code
              of conduct.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Cocmanagement;
