import React, { Component, useEffect, useState } from "react";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import Banner from "./Banner";
import AboutSec from "./AboutSec";
import Features from "./Features";
import ApplySec from "./ApplySec";
import Events from "./Events";
import News from "./News";
import SearchCourse from "./SearchCourse";
import Footer from "../common/footer";
import { MetaTags } from "react-meta-tags";
import EnquiryNow from "../common/EnquiryNow4";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };
  }

  openModal = () => {
    this.setState({ showModal: true });
  };

  // Function to close the modal
  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <>
        <MetaTags>
          <title>ISBM University Chhattisgarh | Top University in India</title>
          <meta
            name="description"
            content="Discover excellence at ISBM University, a leading institution in Chhattisgarh and among the top universities in India. Offering diverse programs and quality education nationwide."
          />
          <link
            rel="canonical"
            href="https://isbmuniversity.edu.in/pay-fees-online"
          />
          <meta
            name="SearchEngines"
            content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
        DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
          />
          <meta name="Robots" content="INDEX, FOLLOW" />
          <meta
            name="Author"
            content="ISBM University, isbmuniversity.edu.in/"
          />
          <meta name="Robots" content="INDEX,ALL" />
          <meta name="YahooSeeker" content="INDEX, FOLLOW" />
          <meta name="msnbot" content="INDEX, FOLLOW" />
          <meta name="googlebot" content="Default, follow" />
          <meta name="owner" content="isbmuniversity.edu.in/" />
          <meta name="language" content="English" />
          <meta name="revisit-after" content="2 days" />
          <meta
            property="og:title"
            content="ISBM University Chhattisgarh | Top University in India"
          />
          <meta
            property="og:description"
            content="Discover excellence at ISBM University, a leading institution in Chhattisgarh and among the top universities in India. Offering diverse programs and quality education nationwide."
          />
          <meta
            property="og:url"
            content="https://isbmuniversity.edu.in/pay-fees-online/"
          />
          <meta
            property="og:image"
            content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
          />
          <meta property="og:image:alt" content="ISBM University" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="ISBM University" />
          <meta
            name="twitter:title"
            content="ISBM University Chhattisgarh | Top University in India"
          />
          <meta
            name="twitter:description"
            content="Discover excellence at ISBM University, a leading institution in Chhattisgarh and among the top universities in India. Offering diverse programs and quality education nationwide."
          />
          <meta
            name="twitter:image:src"
            content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@ISBMUniversity" />
          <meta name="twitter:creator" content="@ISBMUniversity" />
          <meta
            name="twitter:domain"
            content="https://isbmuniversity.edu.in/"
          />
          <meta property="twitter:account_id" content="758619542734053376" />
          <meta
            name="keywords"
            content="ISBM University, University in Chhattisgarh, Top Universities in India"
          />
        </MetaTags>
        <EnquiryNow show={this.state.showModal} onHide={this.closeModal} />
        <Topnav />
        <Menubar />
        <Banner />
        <AboutSec />
        <Features />
        <ApplySec />
        <Events />
        <News />
        <SearchCourse />
        <Footer />
      </>
    );
  }
}

export default Home;
