import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb,Accordion } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import AcademicSidebaar from "../sidebars/AcademicSidebar";
import logo from "../../Images/logo/isbm-university.webp";
import digitoald from "../../Images/MOU/Digitoald.webp";
import digitoaldthumb from "../../Images/MOU/Digitoadimg.webp";
import GULogo from "../../Images/MOU/GU-Logo.webp";
import GUThumb from "../../Images/MOU/GU-thumb.webp";
import aryaLogo from "../../Images/MOU/arya-Logo.webp";
import aryaThumb from "../../Images/MOU/arya-thumb.webp";
import AudyogikLogo from "../../Images/MOU/Audyogik-logo.webp";
import AudyogikThumb from "../../Images/MOU/Audyogik-thumb.webp";
import diuLogo from "../../Images/MOU/diu-logo.webp";
import diuThumb from "../../Images/MOU/diu-thumb.webp";
import internzlearnLogo from "../../Images/MOU/internzlearn-logo.webp";
import internzlearnThumb from "../../Images/MOU/internzlearn-thumb.webp";
import ksrLogo from "../../Images/MOU/ksr-logo.webp";
import ksrThumb from "../../Images/MOU/ksr-thumb.webp";
import GIPERLogo from "../../Images/MOU/GIPER-logo.webp";
import GIPERThumb from "../../Images/MOU/GIPER-thumb.webp";
import ChitkaraLogo from "../../Images/MOU/Chitkara-logo.webp";
import ChitkaraThumb from "../../Images/MOU/Chitkara-thumb.webp";
import INSDLogo from "../../Images/MOU/INSD-logo.webp";
import INSDThumb from "../../Images/MOU/INSD-thumb.webp";
import MaharajaLogo from "../../Images/MOU/Maharaja-logo.webp";
import MaharajaThumb from "../../Images/MOU/Maharaja-thumb.webp";
import MaharishiLogo from "../../Images/MOU/Maharishi-logo.webp";
import MaharishiThumb from "../../Images/MOU/Maharishi-thumb.webp";
import SAMLogo from "../../Images/MOU/SAM-logo.webp";
import SAMThumb from "../../Images/MOU/SAM-thumb.webp";
import ModernLogo from "../../Images/MOU/Modern-logo.webp";
import ModernThumb from "../../Images/MOU/Modern-thumb.webp";
import SushantLogo from "../../Images/MOU/Sushant-logo.webp";
import SushantThumb from "../../Images/MOU/Sushant-thumb.webp";
import ShobitLogo from "../../Images/MOU/Shobit-logo.webp";
import ShobitThumb from "../../Images/MOU/Shobit-thumb.webp";
import TruscholarLogo from "../../Images/MOU/TruScholar-logo.webp";
import TruscholarThumb from "../../Images/MOU/Truscholar-thumb.webp";
import USTMLogo from "../../Images/MOU/USTM-logo.webp";
import USTMThumb from "../../Images/MOU/USTM-thumb.webp";
import FundCorpsLogo from "../../Images/MOU/FundCorps-logo.webp";
import FundCorpsThumb from "../../Images/MOU/FundCorps-thumb.webp";
import SriSriLogo from "../../Images/MOU/SriSri-logo.webp";
import SriSriThumb from "../../Images/MOU/SirSir-thumb.webp";
import CybermotionLogo from "../../Images/MOU/Cybermotion-logo.webp";
import CybermotionThumb from "../../Images/MOU/Cybermotion-thumb.webp";
import rushfordLogo from "../../Images/MOU/rushford-logo.webp";
import rushfordThumb from "../../Images/MOU/rushford-thumb.webp";
import henryLogo from "../../Images/MOU/henry-logo.webp";
import henryThumb from "../../Images/MOU/henry-thumb.webp";
import EnquiryNow from "../common/EnquiryNow3";
import { MetaTags } from "react-meta-tags";

const MOUs = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <MetaTags>
        <title>
          ISBM University: Strengthen through MOUs' & Collaborations
        </title>
        <meta
          name="description"
          content="Discover ISBM University MOUs and collaborations, fostering innovation & academic excellence through dynamic partnerships across diverse fields and industries."
        />
        <link
          rel="canonical"
          href="https://isbmuniversity.edu.in/pay-fees-online"
        />
        <meta
          name="SearchEngines"
          content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
        DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
        />
        <meta name="Robots" content="INDEX, FOLLOW" />
        <meta name="Author" content="ISBM University, isbmuniversity.edu.in/" />
        <meta name="Robots" content="INDEX,ALL" />
        <meta name="YahooSeeker" content="INDEX, FOLLOW" />
        <meta name="msnbot" content="INDEX, FOLLOW" />
        <meta name="googlebot" content="Default, follow" />
        <meta name="owner" content="isbmuniversity.edu.in/" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="2 days" />
        <meta
          property="og:title"
          content="ISBM University: Strengthen through MOUs' & Collaborations"
        />
        <meta
          property="og:description"
          content="Discover ISBM University MOUs and collaborations, fostering innovation & academic excellence through dynamic partnerships across diverse fields and industries."
        />
        <meta
          property="og:url"
          content="https://isbmuniversity.edu.in/pay-fees-online/"
        />
        <meta
          property="og:image"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta property="og:image:alt" content="ISBM University" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ISBM University" />
        <meta
          name="twitter:title"
          content="ISBM University: Strengthen through MOUs' & Collaborations"
        />
        <meta
          name="twitter:description"
          content="Discover ISBM University MOUs and collaborations, fostering innovation & academic excellence through dynamic partnerships across diverse fields and industries."
        />
        <meta
          name="twitter:image:src"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ISBMUniversity" />
        <meta name="twitter:creator" content="@ISBMUniversity" />
        <meta name="twitter:domain" content="https://isbmuniversity.edu.in/" />
        <meta property="twitter:account_id" content="758619542734053376" />
        <meta
          name="keywords"
          content="Exchange Programs , Academic Partners , Undergraduate Courses"
        />
      </MetaTags>

      <Topnav />
      <Menubar />
      <div className="downloadsinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>MOUs' and Collaborations</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>MOUs' and Collaborations with ISBM University</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <AcademicSidebaar/>
          <Col lg={9}>
          <h2 className="linkabout123">MOUs' and Collaborations with ISBM University</h2>
          <ul className="mou" style={{ paddingLeft: 0 }}>
              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={digitoald} alt="DigiToad Technologies" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      ISBM University signed an MoU with DigiToad Technologies
                      Pvt. Ltd. a Bangalore based firm which specializes in
                      Embedded Systems and Engineering solutions with a focus on
                      AI and ML. The objective of the agreement is to bring
                      industry interface to Engineering and Management students
                      of ISBM University. The MoU will also enable students
                      secure industry internships and training in the field.
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={digitoaldthumb} alt="DigiToad Technologies" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={diuLogo} alt="diu Institute of Engineering & Technology" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      diu Institute of Engineering & Technology (DIET) is
                      amongst the foremost of Top Institutes in Rajasthan for
                      Engineering in Higher Technical Education & Research.
                      Established in the year 2005, in the State of Rajasthan,
                      diu Institute of Engineering & Technology has evolved into
                      the most prominent College in the state as well as the
                      Best Engineering Colleges in Jaipur.
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={diuThumb} alt="diu Institute of Engineering & Technology" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={internzlearnLogo} alt="internzlearn Institute of Engineering & Technology" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      internzlearn Institute of Engineering & Technology (IIET)
                      is amongst the foremost of Top Institutes in Rajasthan for
                      Engineering in Higher Technical Education & Research.
                      Established in the year 2005, in the State of Rajasthan,
                      internzlearn Institute of Engineering & Technology has
                      evolved into the most prominent College in the state as
                      well as the Best Engineering Colleges in Jaipur.
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={internzlearnThumb} alt="internzlearn Institute of Engineering & Technology" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={ksrLogo} alt="ksr Institute of Engineering & Technology" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      ksr Institute of Engineering & Technology (KIET) is
                      amongst the foremost of Top Institutes in Rajasthan for
                      Engineering in Higher Technical Education & Research.
                      Established in the year 2005, in the State of Rajasthan,
                      ksr Institute of Engineering & Technology has evolved into
                      the most prominent College in the state as well as the
                      Best Engineering Colleges in Jaipur.
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={ksrThumb} alt="ksr Institute of Engineering & Technology" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={aryaLogo} alt="Arya Institute of Engineering & Technology (AIET)" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      Arya Institute of Engineering & Technology (AIET) is
                      amongst the foremost of Top Institutes in Rajasthan for
                      Engineering in Higher Technical Education & Research.
                      Established in the year 2005, in the State of Rajasthan,
                      Arya Institute of Engineering & Technology has evolved
                      into the most prominent College in the state as well as
                      the Best Engineering Colleges in Jaipur.
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={aryaThumb} alt="Arya Institute of Engineering & Technology (AIET)" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={GULogo} alt="Geeta University" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      Geeta University comes from the Lineage of Geeta Group of
                      Institutions, A giant educational hub with presence since
                      1985.
                      <br />
                      Geeta Group of Institutions have laid the runway for
                      thousands of dreams taking off to the destination of
                      careers with the journey marked with expertise, experience
                      and excellence.
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={GUThumb} alt="Geeta University" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={AudyogikLogo} alt="Audyogik" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      Pioneers in the field of Management Education in Pimpri-
                      Chinchwad, Asias largest Industrial belt, ASMs IBMR is the
                      first institute in Maharashtra to receive an AICTE
                      approval. The institute is permanently affiliated to the
                      Savitribai Phule Pune University and also approved by the
                      Ministry of HRD, Govt. of India.
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={AudyogikThumb} alt="Audyogik" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={GIPERLogo} alt="Global Institute of Pharmaceutical Education and Research" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      Global Institute of Pharmaceutical Education and Research,
                      Kashipur established in 2009 is a premier institution in
                      the field of pharmaceutical sciences for fulfilling the
                      emerging need of the society and country and to make India
                      a World leader in the field of pharmaceutical science.
                    </p>
                    <a
                      target="/"
                      className="col-lg-2 col-md-2 col-sm-12"
                      //href={GIPERpdf}
                    >
                      <img src={GIPERThumb} alt="Global Institute of Pharmaceutical Education and Research" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={ChitkaraLogo} alt="Chitkara University" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      Chitkara University offers a world of learning that will
                      help you reach your goals. Choose from our varied industry
                      endorsed programs. Learn from an outstanding faculty at
                      our state-of-the-art teaching facilities. Explore
                      unlimited opportunities to get placed with one of the
                      1500+ campus and meet other students with dreams as big as
                      yours.
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={ChitkaraThumb} alt="Chitkara University" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={INSDLogo} alt="INSD" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      A pioneer in evolving design education in our country,
                      INSD focuses on bridging the gap between Academics and the
                      Industry. The ideology behind our education system is to
                      contribute significantly to the development of Indian
                      Design Industry by tapping on hidden talent, passion,
                      dedication, innovation and tremendous potential that the
                      today’s youth showcases.
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={INSDThumb} alt="INSD" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={MaharajaLogo} alt="Maharaja Agrasen Himalayan Garhwal University (MAHGU)" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      Maharaja Agrasen Himalayan Garhwal University (MAHGU) is
                      established and incorporated by Government of Uttarakhand
                      Act No. 33 of 2016. Vide Notification no. XXXVI
                      (3)/2016/48(1)/2016) of Uttarakhand Shashan (Vidhayee &
                      Sansdiya Karyavibhag) and under Section 2(f) of the
                      University Grant Commission (UGC) Act, 1956.
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={MaharajaThumb} alt="Maharaja Agrasen Himalayan Garhwal University (MAHGU)" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={MaharishiLogo} alt="Maharishi Mahesh Yogi Vedic Vishwavidyalaya (MMYVV)" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      The Maharishi Mahesh Yogi Vedic Vishwavidyalaya (MMYVV)
                      was established by the Act No. 37 of 1995 of Madhya
                      Pradesh Government vide gazette notification no 573 dated
                      29 November 1995. The University head office is at –
                      Brahmasthan of India, Karaundi, District-Katni, Madhya
                      Pradesh. The University is reputed to disseminate quality
                      education.
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={MaharishiThumb} alt="Maharishi Mahesh Yogi Vedic Vishwavidyalaya (MMYVV)" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={SAMLogo} alt="SAM Global University" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      SAM Global University, established in 2019 under the
                      umbrella of the Shri Guru Hargobind Society, has been
                      imparting quality education for the last six decades.
                      Approved by Madhya Pradesh Niji Vishwavidhyalaya (Sthapana
                      Avam Sanchalan) Adhiniyam 2007 and UGC, the SGU- best
                      university in MP, is located at Agariya Chopra, Bhopal
                      Road, Raisen (MP).
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={SAMThumb} alt="SAM Global University" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img
                    src={ModernLogo}
                    alt="MGI"
                    style={{ background: "#000", padding: "5px" }}
                  />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      MGI was established in 2007 as a dream of Late Shri P.C.
                      Kharia as an education venture to impart education in
                      Pharmacy Management education, & commerce streams .
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={ModernThumb} alt="MGI" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={SushantLogo} alt="Sushant University (Erstwhile Ansal University)" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      Sushant University (Erstwhile Ansal University) was
                      established in 2012 under the Haryana Private Universities
                      Act 2006. Located in the heart of Gurugram, India’s
                      largest hub of National and Fortune 500 companies. We have
                      eight schools offering programmes in Architecture, Design,
                      Law, Management, Hospitality, Engineering, Health Sciences
                      and Planning & Development.
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={SushantThumb} alt="Sushant University (Erstwhile Ansal University)" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={ShobitLogo} alt="Shobhit University" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      Shobhit University believes in strategic growth as
                      envisaged in our mission and vision, to impart quality
                      education through vertical and horizontal integration. It
                      strives to become a world class university, with a global
                      perspective, that educates the future leaders of the
                      world.
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={ShobitThumb} alt="Shobhit University" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={TruscholarLogo} alt="TruScholar" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      TruScholar empowers institutions across the globe with
                      secure infrastructure to establish tamper-proof digital
                      credentials, digital certificates and badges.
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={TruscholarThumb} alt="TruScholar" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={USTMLogo} alt="University of Science & Technology Meghalaya" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      The University of Science & Technology Meghalaya (USTM) is
                      the first State Private University of Science & Technology
                      in the entire North East India—a University that has been
                      awarded the accreditation rating of “A Grade” in its first
                      cycle of assessment by the National Assessment and
                      Accreditation Council in 2021.
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={USTMThumb} alt="University of Science & Technology Meghalaya" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={FundCorpsLogo} alt="Funds And Toil" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      Funds And Toil was launched in mid-2015 as a management
                      consulting firm to support young entrepreneurs ideate,
                      launch, and grow their businesses. We are a company with a
                      difference. We support entrepreneurs (non-profits and
                      for-profits) through management & strategic advisory, a
                      fruitful network, and capital for impact growth. We speak
                      entrepreneurs' language, share the same experiences,
                      understand the same challenges - and we believe in the
                      vision of entrepreneurs we support.
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={FundCorpsThumb} alt="Funds And Toil" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={SriSriLogo} alt="Sri Sri University" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      On 22 February 2012, Sri Sri University was notified by
                      the Higher Education Department of the Government of
                      Odisha as a legal entity after clearing a High Power
                      Committee (HPC) scrutiny of its infrastructure, academic,
                      regulatory, financial and manpower preparedness. The
                      notification has featured in an extraordinary issue of
                      Government of Odisha Gazette.
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={SriSriThumb} alt="Sri Sri University" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={CybermotionLogo} alt="Cybermotion Technologies" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      Cybermotion Technologies Pvt. Ltd. is a professionally
                      managed company, working with Technical Educational
                      institutes, DRDO, CSIR Labs, Private Industries all over
                      the country for Electronics System Design and Simulation
                      solutions, for the past 30 years. The company has strong
                      domain knowledge and expertise in the area of Automation,
                      Controls and Electronics System Design.{" "}
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={CybermotionThumb} alt="Cybermotion Technologies" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={rushfordLogo} alt="RUSHFORD Business School" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      ISBM University has signed a Memorandum of Understanding
                      (MoU) with RUSHFORD Business School, Switzerland |
                      Germany, for enhancement of academic, scientific and
                      technical cooperation and to promote educational exchange
                      in various fields. Through this MoU the students can opt
                      for a wide range of specialised business courses and
                      programs, including Business Administration, Management,
                      Business Analytics, AI for Robotics, Hospitality & Tourism
                      Management, Engineering Management, and many more. The
                      students enrolled in the programs will graduate with a
                      Dual Degree with additional job benefits.
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={rushfordThumb} alt="RUSHFORD Business School" />
                    </a>
                  </div>
                </div>
              </li>

              <li className="d-flex flex-wrap">
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={logo} alt="One of the best University in India" />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 mou-img">
                  <img src={henryLogo} alt="Henry Harvin" />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="aboutMou">
                    <p className="col-lg-10 col-md-10 col-sm-12 pe-2">
                      ISBM University has signed a Memorandum of Understanding
                      (MoU) with Henry Harvin®, a global Edtech Company based
                      out of US and India, to conduct Training and Placement
                      programs for its Students in over 600+ courses available
                      with the Edtech company like Six Sigma Green Belt,
                      Tableau, Analytics using Python/R (Business, Marketing,
                      HR, Finance), Blockchain Professional Course, Data Science
                      with Python Course, etc. The objective of the MoU is to
                      offer on-the-job training and practical training to
                      students of either diploma, graduation or post graduation
                      in any stream to enhance their employability and counter
                      the issues with respect to skilled labour scarcity in the
                      region.
                    </p>
                    <a target="/" className="col-lg-2 col-md-2 col-sm-12">
                      <img src={henryThumb} alt="Henry Harvin" />
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default  MOUs;
