import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Accordion } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Servicesslidebaar from "../sidebars/ServicesSidebar";
import EnquiryNow from "../common/EnquiryNow3";

const AntiRaggingSquad = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="antiragginginnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Anti-Ragging Squad</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Anti-Ragging Squad</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Servicesslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Anti-Ragging Squad</h2>
            <div className="position-relative card table-nowrap table-card">
              <div className="table-responsive committe-table">
                <table className="table mb-0">
                  <thead className="small text-uppercase bg-body text-muted bg-secondary">
                    <tr>
                      <th>Name of Member</th>
                      <th>Designation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="small">Dr. N. Kumar Swamy</td>
                      <td className="small">Chairperson </td>
                    </tr>
                    <tr>
                      <td className="small">Dr. Sandeep Kumar</td>
                      <td className="small">Member </td>
                    </tr>
                    <tr>
                      <td className="small">Mr. Rajendra Prasad Gendre </td>
                      <td className="small">Member </td>
                    </tr>
                    <tr>
                      <td className="small">Dr. Garima Diwan </td>
                      <td className="small">Member </td>
                    </tr>
                    <tr>
                      <td className="small">Dr. Poonam Verma </td>
                      <td className="small">Member </td>
                    </tr>
                    <tr>
                      <td className="small">Smt. Kesharlata Sahu</td>
                      <td className="small">Member </td>
                    </tr>
                    <tr>
                      <td className="small">Ms. Rekha Sahu</td>
                      <td className="small">Member</td>
                    </tr>
                    <tr>
                      <td className="small">Ms. Lalita Sahu</td>
                      <td className="small">Member </td>
                    </tr>
                    <tr>
                      <td className="small">Mr. Pukhraj Yadaw </td>
                      <td className="small">Member</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default AntiRaggingSquad;
